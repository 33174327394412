<template>
  <div class="yx-wid footer">
    <div class="yx-wid-12">
      <ul class="link-list">
        <li v-for="(item, index) in links" :key="index">
          <img :src="item.img" class="mr" />
          <img :src="item.imgHover" class="move" />
        </li>
      </ul>
      <div class="copy" v-html="$t('footer.smcopy')">
      </div>
      <ul class="rz-list">
        <li>
          <span></span>
          <p>{{ $t('footer.pttext') }}</p>
        </li>

        <li>
          <span></span>
          <p>{{ $t('footer.pttext1') }}</p>
        </li>

        <li>
          <span></span>
          <p>{{ $t('footer.pttext2') }}</p>
        </li>
      </ul>
      <div class="guide-list">
        <a href="javascript:;" @click="jumpGO(item)" v-for="(item, index) in guides" :key="index">{{ item.name }}</a>
      </div>
      <div class="copy-text">{{ $t('footer.copytext') }}</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      links: [
        {
          name: "",
          img: require("@/assets/images/logo/logo01-1.png"),
          imgHover: require("@/assets/images/logo/logo01.png"),
        },
        {
          name: "",
          img: require("@/assets/images/logo/logo02-1.png"),
          imgHover: require("@/assets/images/logo/logo02.png"),
        },
        {
          name: "",
          img: require("@/assets/images/logo/logo03-1.png"),
          imgHover: require("@/assets/images/logo/logo03.png"),
        },
        {
          name: "",
          img: require("@/assets/images/logo/logo04-1.png"),
          imgHover: require("@/assets/images/logo/logo04.png"),
        },
        {
          name: "",
          img: require("@/assets/images/logo/logo05-1.png"),
          imgHover: require("@/assets/images/logo/logo05.png"),
        },
        {
          name: "",
          img: require("@/assets/images/logo/logo06-1.png"),
          imgHover: require("@/assets/images/logo/logo06.png"),
        },
        {
          name: "",
          img: require("@/assets/images/logo/logo07-1.png"),
          imgHover: require("@/assets/images/logo/logo07.png"),
        },
        {
          name: "",
          img: require("@/assets/images/logo/logo08-1.png"),
          imgHover: require("@/assets/images/logo/logo08.png"),
        },
        {
          name: "",
          img: require("@/assets/images/logo/logo09-1.png"),
          imgHover: require("@/assets/images/logo/logo09.png"),
        },
        {
          name: "",
          img: require("@/assets/images/logo/logo10-1.png"),
          imgHover: require("@/assets/images/logo/logo10.png"),
        },
        {
          name: "",
          img: require("@/assets/images/logo/logo11-1.png"),
          imgHover: require("@/assets/images/logo/logo11.png"),
        },
        {
          name: "",
          img: require("@/assets/images/logo/logo12-1.png"),
          imgHover: require("@/assets/images/logo/logo12.png"),
        },
        {
          name: "",
          img: require("@/assets/images/logo/logo13-1.png"),
          imgHover: require("@/assets/images/logo/logo13.png"),
        },
        {
          name: "",
          img: require("@/assets/images/logo/logo14-1.png"),
          imgHover: require("@/assets/images/logo/logo14.png"),
        },
      ],
      guides: [
        { name: this.$i18n.t('footer.nav1'), link: "/help?type=kh" },
        { name: this.$i18n.t('footer.nav2'), link: "/help?type=cpqa" },
        { name: this.$i18n.t('footer.nav3'), link: "/help?type=ysbhgz" },
        { name: this.$i18n.t('footer.nav4'), link: "/help?type=gzytk" },
        { name: this.$i18n.t('footer.nav5'), link: "/help?type=lxwm" },
        { name: this.$i18n.t('footer.nav6'), link: "/agent" },
        { name: this.$i18n.t('footer.nav7'), link: "/download" },
      ],
    };
  },
  methods: {
    jumpGO(val) {
      if (!val.link) return;
      this.$router.push(val.link);
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  background: #30383f;
  color: #878ea6;
  width: 100%;
  text-align: center;
  position: relative;
  line-height: 1;
}

.link-list {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 0;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    background: rgba(113, 128, 153, 0.2);
  }

  li {
    &:hover {
      .mr {
        display: none;
      }

      .move {
        display: block;
      }
    }
  }

  img {
    height: 50px;

    &.move {
      display: none;
    }
  }
}

.copy {
  display: flex;
  justify-content: center;
  line-height: 1.4;
  text-align: center;
  padding: 0 130px;
  margin-top: 18px;
  font-size: 14px;
  color: #878ea6;
  flex-direction: column;
}

.rz-list {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 0;

  li {
    margin: 0 60px;
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    &:hover {
      color: white;

      p {
        color: white;
      }

      span {
        background-position: -167px 0;
      }
    }

    span {
      width: 165px;
      height: 34px;
      display: block;
      background: url("@/assets/images/logo15.png") no-repeat;
      background-size: 958px 41px;
      background-position: 0 0;
    }

    p {
      font-size: 14px;
      color: #878ea6;
      margin-top: 8px;
    }

    &:nth-child(2) {
      &:hover {
        span {
          background-position: -507px 0;
        }
      }

      span {
        width: 171px;
        height: 41px;
        background-position: -334px 0;
      }
    }

    &:nth-child(3) {
      &:hover {
        span {
          background-position: -820px 0;
        }
      }

      span {
        width: 138px;
        height: 41px;
        background-position: -680px 0;
      }
    }
  }
}

.guide-list {
  display: flex;
  justify-content: center;
  color: #c0c4d0;
  font-size: 14px;
  padding: 16px 0 0;

  a {
    position: relative;
    padding: 0 16px;
    color: #c0c4d0;
    border-right: 1px solid #d6e2f3;

    &:last-child {
      border-right: none;
    }

    &:hover {
      color: #2599f8;
    }
  }
}

.copy-text {
  text-align: center;
  margin-top: 14px;
  line-height: 32px;
  font-size: 12px;
  color: #7c8797;
}
</style>
