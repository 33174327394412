<template>
  <div class="vipCenter-bg">
    <div class="yx-wid-12 vipCenter">
      <div class="user-left" v-if="userInfo">
        <!-- 头像部分 -->
        <div class="userInfo">
          <div class="photo" @click="setPhoto">
            <img v-lazy="userInfo.photoUrl" />
            <span class="text-ak">{{ userInfo.levelName }}</span>
          </div>
          <div class="name">
            <span class="text">{{ userInfo.userName }}</span>
            <!-- <span class="level">
              <img v-if="userInfo && userInfo.levelId >= 0" :src="require('@/assets/images/mine/level/vip' +
                userInfo.levelId +
                '.webp')
                " />
            </span> -->
          </div>
          <p class="time" v-if="userInfo && baseInfo && baseInfo[2]">
            {{ $t("mine.index.252217-0") }}{{ baseInfo[2].configValue
            }}{{ userInfo.joinDays }}{{ $t("mine.index.252217-1") }}
          </p>
          <div class="account-dj">安全等级：低</div>
          <div class="account-state">
            <span :class="{ hover: userInfo.mobile }">
              <img src="@/assets/images/mine/userinfo/phone.webp" />
            </span>
            <span :class="{ hover: userInfo.email }">
              <img src="@/assets/images/mine/userinfo/email.webp" />
            </span>
            <span :class="{ hover: userInfo.realName }">
              <img src="@/assets/images/mine/userinfo/info.webp" />
            </span>
            <span :class="{ hover: userBankCardList }">
              <img src="@/assets/images/mine/userinfo/bank.png" />
            </span>
          </div>
        </div>
        <!-- menus -->
        <div class="user-nav">
          <ul class="user-box-ul">
            <li
              :class="{ hover: isHover === '/finance/deposit' }"
              @click="$router.push('/finance/deposit')"
            >
              <span class="icon">
                <img src="@/assets/images/mine/icon01-1.png" class="mr" />
                <img src="@/assets/images/mine/icon01-2.png" class="xz" />
              </span>
              <p>{{ $t("mine.index.252217-2") }}</p>
            </li>
            <li
              :class="{ hover: isHover === '/finance/transfer' }"
              @click="$router.push('/finance/transfer')"
            >
              <span class="icon">
                <img src="@/assets/images/mine/icon02-1.png" class="mr" />
                <img src="@/assets/images/mine/icon02-2.png" class="xz" />
              </span>
              <p>{{ $t("mine.index.252217-3") }}</p>
            </li>
            <li
              :class="{ hover: isHover === '/finance/withdrawal' }"
              @click="$router.push('/finance/withdrawal')"
            >
              <span class="icon">
                <img src="@/assets/images/mine/icon03-1.png" class="mr" />
                <img src="@/assets/images/mine/icon03-2.png" class="xz" />
              </span>
              <p>{{ $t("mine.index.252217-4") }}</p>
            </li>
          </ul>
          <ul class="user-nav-ul" v-for="(item, index) in menus" :key="index">
            <li
              v-for="(row, ind) in item"
              :key="ind"
              :class="{ hover: row.aid === menuInd }"
              @click="tabMenu(row)"
            >
              <span class="icon">
                <img :src="row.icon" class="mr" />
                <img :src="row.iconHover" class="xz" />
              </span>
              <span class="text">{{ row.name }}</span>
              <span class="message" v-if="row.aid === 8 && message > 0">{{
                message
              }}</span>
            </li>
          </ul>
        </div>
      </div>
      <router-view></router-view>
      <SetPhoto
        v-if="isExitPhoto && userInfo && userInfo.photoUrl"
        :visible.sync="isExitPhoto"
        :title="$t('mine.index.252217-39')"
      />
      <helpCenter v-if="isHelp" :visible.sync="isHelp" :scrollTop="helpTop" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getTime } from "@/mixins/getTime";
import SetPhoto from "@/components/SetPhoto.vue";
import helpCenter from "@/components/TransferHelp.vue";
export default {
  components: { SetPhoto, helpCenter },
  data() {
    return {
      isHelp: false,
      helpTop: 0,
      menuInd: undefined,
      menus: [
        [
          {
            name: this.$t("mine.index.252217-40"),
            link: "/mine/userInfo",
            aid: 1,
            icon: require("@/assets/images/mine/icon04.png"),
            iconHover: require("@/assets/images/mine/icon04-1.png"),
          },
          {
            name: this.$t("mine.index.252217-41"),
            link: "/mine/vip",
            aid: 2,
            icon: require("@/assets/images/mine/icon05.png"),
            iconHover: require("@/assets/images/mine/icon05-1.png"),
          },
          {
            name: this.$t("mine.index.252217-42"),
            link: "/record/welfare",
            aid: 3,
            icon: require("@/assets/images/mine/icon06.png"),
            iconHover: require("@/assets/images/mine/icon06-1.png"),
          },
          {
            name: "取款卡",
            link: "/finance/banks",
            aid: 10,
            icon: require("@/assets/images/mine/icon101.png"),
            iconHover: require("@/assets/images/mine/icon101-1.png"),
          },
        ],
        [
          {
            name: this.$t("mine.index.252217-43"),
            link: "/finance/wallet",
            aid: 4,
            icon: require("@/assets/images/mine/icon07.png"),
            iconHover: require("@/assets/images/mine/icon07-1.png"),
          },
          {
            name: this.$t("mine.index.252217-44"),
            link: "/record/transRecord",
            aid: 5,
            icon: require("@/assets/images/mine/icon08.png"),
            iconHover: require("@/assets/images/mine/icon08-1.png"),
          },
          {
            name: this.$t("mine.index.252217-5"),
            link: "/record/betRecord",
            aid: 6,
            icon: require("@/assets/images/mine/icon09.png"),
            iconHover: require("@/assets/images/mine/icon09-1.png"),
          },
        ],
        [
          {
            name: this.$t("mine.index.252217-45"),
            link: "/record/giftRecord",
            aid: 7,
            icon: require("@/assets/images/mine/icon10.png"),
            iconHover: require("@/assets/images/mine/icon10-1.png"),
          },
          {
            name: this.$t("mine.index.252217-46"),
            link: "/mail",
            aid: 8,
            icon: require("@/assets/images/mine/icon11.png"),
            iconHover: require("@/assets/images/mine/icon11-1.png"),
          },
          {
            name: this.$t("mine.index.252217-47"),
            link: "/feedback/list",
            aid: 9,
            icon: require("@/assets/images/mine/icon12.png"),
            iconHover: require("@/assets/images/mine/icon12-1.png"),
          },
        ],
      ],
      isExitPhoto: false,
      carkInd: 0,
      isShowRight: false,
      isHover: "",
      recordList: null, //投注列表
      // 卡片参数
      bankListMenu: [
        {
          name: this.$t("mine.index.252217-8"),
          type: 1,
          moreLink: "/finance/wallet",
          addLink: "/addBankCard",
        },
        {
          name: this.$t("mine.index.252217-9"),
          type: 2,
          moreLink: "/virtualCoinCard",
          addLink: "/virtualCoinCard",
        },
      ],
      bankList: null,
      // 存款参数
      ckList: null,
      // 反馈列表
      facebookList: null,
      // 活动列表
      activityList: null,
      userBankCardList: null,
    };
  },
  watch: {
    $route(val) {
      if (val.meta.isShow) {
        this.isShowRight = true;
      } else {
        this.isShowRight = false;
      }

      this.infoLeftVal();
      this.initPost();
    },
    isLogin(val) {
      if (val) {
        this.getUserInfo();
      }
    },
  },
  computed: {
    ...mapState(["userInfo", "message"]),
  },
  mounted() {
    this.infoRoute();
    this.infoLeftVal();
    this.getUnReadMessageCount();
    this.initPost();
    this.queryUserBankCardList();
  },
  methods: {
    queryUserBankCardList() {
      this.$Api.queryUserBankCardList().then((res) => {
        if (res) {
          this.userBankCardList = res.userBankCardList;
        }
      });
    },
    openHelp(val) {
      this.isHelp = true;
      document.body.style = "overflow:hidden";
      this.helpTop = val;
    },
    // 个人资料
    getUserInfo() {
      this.$Api.getUserInfo().then((res) => {
        if (res) {
          this.$store.dispatch("setUserInfoAc", res.userInfo);
        }
      });
    },
    // 设置头像
    setPhoto() {
      this.isExitPhoto = true;
    },
    // 切换银行卡
    tabBankList(index) {
      this.carkInd = index;
      if (index === 0) {
        this.cardPost();
      } else {
        this.cardPostUSDT();
      }
    },
    // 初始化调对应接口
    initPost() {
      const url = this.$route.path;
      // 个人资料
      if (url === "/mine/userInfo") {
        this.tqPost(); //投注接口
        this.cardPost(); //银行卡接口
      }
      if (url === "/finance/wallet") {
        this.tqPost(); //投注接口
      }
      if (url === "/finance/deposit") {
        this.ckPost(); //存款接口
      }
      if (url === "/finance/transfer") {
        this.ckPost("transfer"); //转账
      }
      if (url === "/finance/withdrawal") {
        this.ckPost("withdraw"); //取款
      }
      if (url === "/feedback") {
        this.getFeedbackList();
      }
      if (["/finance/deposit", "/finance/wallet"].includes(url)) {
        this.getActivityList();
      }
    },
    // 活动列表
    getActivityList() {
      const params = {
        activityClassId: "",
        device: 1,
        pageIndex: 1,
        pageSize: 5,
      };
      this.$Api.getActivityList(params).then((res) => {
        if (res) {
          this.activityList = res.activityList.records;
        }
      });
    },
    // 反馈列表
    getFeedbackList() {
      const params = {
        pageIndex: 1,
        pageSize: 20,
      };
      this.$Api.getFeedbackList(params).then((res) => {
        if (res) {
          this.facebookList = res.page.list;
        }
      });
    },
    openFacebook(val) {
      this.$router.push("/feedback/detail?order=" + val.id);
    },
    // 存款、转账、取款接口
    ckPost(val) {
      const params = {
        transType: val ? val : "recharge",
        startTime: getTime(),
        endTime: getTime(),
        pageIndex: 1,
        pageSize: 5,
      };
      this.$Api.getUserTransInfo(params).then((res) => {
        if (res) {
          this.ckList = res.page.records;
        }
      });
    },
    // 卡片列表
    cardPost() {
      this.$Api.queryUserBankCardList().then((res) => {
        if (res) {
          res.userBankCardList.forEach((item) => {
            item.cardType =
              item.cardType === 1
                ? this.$t("mine.index.252217-48")
                : this.$t("mine.index.252217-49");
          });
          this.bankList = res.userBankCardList;
          if (res.userBankCardList.length) {
            this.$store.dispatch("setIsBankLengthAc", true);
          }
        }
      });
    },
    cardPostUSDT() {
      this.$Api.queryUserBlockchainList().then((res) => {
        if (res) {
          res.userBlockchainList.forEach((item) => {
            item.iconUrl = require("@/assets/images/pay/xnt.png");
            item.bankName = item.name;
            item.bankcardNo = item.address;
            item.cardType = item.networkType;
            item.class = "usdt";
          });
          this.bankList = res.userBlockchainList;
        }
      });
    },
    // 投注接口
    tqPost() {
      const params = {
        platType: "",
        startTime: getTime(),
        endTime: getTime(),
        pageIndex: 1,
        pageSize: 5,
      };
      this.$Api.getBetRecord(params).then((res) => {
        if (res) {
          this.recordList = res.page.records;
        }
      });
    },
    // 投注状态
    getState(e) {
      const arr = [
        this.$t("mine.index.252217-50"),
        this.$t("mine.index.252217-51"),
        this.$t("mine.index.252217-52"),
      ];
      return arr[e];
    },
    // message
    getUnReadMessageCount() {
      this.$Api.getUnReadMessageCount().then((res) => {
        if (res) {
          const message =
            res.data.totalCount > 99 ? "99+" : res.data.totalCount;
          this.$store.dispatch("setMessageAc", message);
        }
      });
    },
    infoLeftVal() {
      if (
        [
          "/finance/deposit",
          "/finance/transfer",
          "/finance/withdrawal",
        ].includes(this.$route.fullPath)
      ) {
        this.isHover = this.$route.fullPath;
        this.menuInd = undefined;
      } else {
        this.isHover = "";
      }
      this.menus.map((item) => {
        if ("/mine/details" === this.$route.fullPath) {
          this.menuInd = 2;
          return;
        }
        item.find((row) => {
          if (row.link === this.$route.fullPath) {
            this.menuInd = row.aid;
          }
        });
      });
    },
    infoRoute() {
      if (this.$route.meta.isShow) {
        this.isShowRight = true;
      } else {
        this.isShowRight = false;
      }
    },
    tabMenu(item) {
      this.menuInd = item.aid;
      this.$router.push(item.link);
    },
    convertType(val) {
      let textVal = "";
      switch (val) {
        case 1:
          textVal = this.$t("mine.index.252217-53");
          break;
        case 2:
          textVal = this.$t("mine.index.252217-54");
          break;
        case 3:
          textVal = this.$t("mine.index.252217-55");
          break;
        case 4:
          textVal = this.$t("mine.index.252217-56");
          break;
        case 5:
          textVal = this.$t("mine.index.252217-57");
          break;
        case 6:
          textVal = this.$t("mine.index.252217-58");
          break;
      }
      return textVal;
    },
    typeName(val) {
      const options = [
        {
          value: 1,
          label: this.$t("mine.index.252217-59"),
        },
        {
          value: 2,
          label: this.$t("mine.index.252217-60"),
        },
        {
          value: 3,
          label: this.$t("mine.index.252217-61"),
        },
        {
          value: 4,
          label: this.$t("mine.index.252217-62"),
        },
        {
          value: 5,
          label: this.$t("mine.index.252217-63"),
        },
        {
          value: 6,
          label: this.$t("mine.index.252217-64"),
        },
        {
          value: 7,
          label: this.$t("mine.index.252217-65"),
        },
        {
          value: 8,
          label: this.$t("mine.index.252217-66"),
        },
        {
          value: 9,
          label: this.$t("mine.index.252217-67"),
        },
      ];
      return options.find((item) => item.value === val).label;
    },
  },
};
</script>

<style lang="scss" scoped>
.vipCenter {
  display: flex;
  padding-top: 100px;
  overflow: hidden;
  width: 1200px;

  &-bg {
    background: url("@/assets/images/mine/bg.webp") no-repeat center top;
    background-size: cover;
    padding-bottom: 30px;
  }
}

.user-left {
  overflow: hidden;
  box-shadow: 0 4px 16px rgba(152, 161, 182, 0.04);
  width: 220px;
  height: auto;
  margin-right: 20px;
  flex: none;
  display: flex;
  flex-direction: column;
}

.userInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(270deg, #4d7afc 0%, #78b8ff 100%);
  border-radius: 12px;
  box-shadow: 0 5px 10px 0 rgba(23, 58, 119, 0.1);
  padding: 20px 0;

  .photo {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    border: 4px solid white;

    &:hover {
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: url("@/assets/images/xj.png") no-repeat rgba(0, 0, 0, 0.3)
          center center;
        background-size: 24px auto;
        border-radius: 50%;
      }
    }

    img {
      width: 100%;
      height: 100%;
      background-color: #35376d59;
      border-radius: 100%;
    }

    span {
      background: linear-gradient(0, #f3d662, #eeb147);
      font-size: 12px;
      position: absolute;
      right: -14px;
      top: 0;
      border-radius: 3px;
      color: white;
      font-weight: bold;
      padding: 0 4px;
      font-style: italic;
      transform: scale(0.9) scaleY(0.8);
      text-indent: 1px;
    }
  }

  .name {
    display: flex;
    align-items: center;
    margin-top: 12px;

    .text {
      height: 20px;
      line-height: 20px;
      font-size: 14px;
      color: white;
      font-weight: 400;
      font-family: DIN, PingFangSC-Regular, sans-serif !important;
    }

    .level {
      display: inline-block;
      vertical-align: middle;
      margin-left: 5px;
      cursor: pointer;
      width: 52px;
      height: 24px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .time {
    height: 17px;
    line-height: 17px;
    font-size: 12px;
    color: white;
  }
}

.user-nav {
  padding: 0 12px;
  flex: 1;
  background: white;
  border-radius: 12px;
  margin-top: 12px;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.05);

  .user-box-ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 20px 24px 0;

    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;

      &:hover,
      &.hover {
        .icon {
          .xz {
            display: block;
          }

          .mr {
            display: none;
          }
        }

        p {
          color: #2599f8;
        }
      }

      .icon {
        position: relative;
        width: 32px;
        height: 32px;

        img {
          width: 100%;
        }

        .xz {
          display: none;
        }
      }
    }
  }

  .user-nav-ul {
    padding: 16px 0;
    border-bottom: 1px solid #e4e8eb;

    &:last-child {
      border-bottom: none;
    }

    li {
      position: relative;
      overflow: hidden;
      background: transparent;
      border-left: 3px solid transparent;
      display: flex;
      align-items: center;
      cursor: pointer;
      color: #788b9d;
      height: 52px;

      &.hover,
      &:hover {
        background: #f4faff;
        border-radius: 12px;

        .icon {
          .xz {
            display: block;
          }

          .mr {
            display: none;
          }
        }

        .text {
          color: #2599f8;
        }
      }

      .icon {
        width: 20px;
        margin: 0 10px 0 16px;

        img {
          width: 100%;
        }

        .xz {
          display: none;
        }
      }
    }
  }
}

.account-dj {
  color: white;
  font-size: 12px;
  margin-top: 16px;
}

.account-state {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 15%;
  margin-top: 6px;

  span {
    width: 22px;
    height: 22px;
    opacity: 0.5;

    &.hover {
      opacity: 1;
    }

    img {
      width: 100%;
    }
  }
}

.message {
  background: red;
  color: white;
  padding: 2px 7px;
  font-size: 12px;
  margin-left: 6px;
  line-height: 1;
  border-radius: 8px;
}
</style>
