<template>
  <div class="pop-check">
    <div class="main">
      <div class="check-title">
        <strong>{{ title }}</strong>
        <span class="close" @click="close"></span>
      </div>
      <div class="check-main">
        <p class="check-sec" v-if="userInfo && userInfo.mobile">
          {{ $t('components.CheckMobile.086753-0') }}<br />
          {{ phoneShow(userInfo.mobile) }}
        </p>
        <div class="check-input">
          <input
            v-model="from.code"
            maxlength="4"
            class="input-check"
            type="text"
          />
          <div class="check-span">
            <span>{{ from.code[0] }}</span>
            <span>{{ from.code[1] }}</span>
            <span>{{ from.code[2] }}</span>
            <span>{{ from.code[3] }}</span>
          </div>
          <p class="text-red" v-if="isZQ">{{ $t('components.CheckMobile.086753-1') }}</p>
        </div>
        <div class="link">
          <el-button
            type="primary"
            class="link-a"
            :disabled="from.code.length !== 4"
            @click="checkMobile"
            >{{ $t('components.CheckMobile.086753-2') }}</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      from: {
        phone: "",
        code: "",
      },
      isZQ: false,
    };
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  watch: {
    userInfo(val) {
      if (val) {
        this.userInfo.mobile.slice(3);
        this.initFrom();
      }
    },
  },
  methods: {
    close() {
      this.$emit("update:visible", false);
    },
    upDataState() {
      this.$emit("next");
    },
    checkMobile() {
      const tt = this.userInfo.mobile.slice(3, 7);
      console.log(this.from.code, tt);
      // return;
      if (tt !== this.from.code) {
        this.isZQ = true;
      } else {
        this.isZQ = false;
        this.close();
        this.upDataState();
      }
    },
    initFrom() {
      this.from.phone = this.phoneShow(this.userInfo.mobile);
    },
  },
};
</script>

<style lang="scss" scoped>
.check-input {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 20px 0 40px;
  .text-red {
    position: absolute;
    bottom: 15px;
    left: 0;
    right: 0;
    text-align: center;
  }
  .check-span {
    display: flex;
    align-items: center;
    position: absolute;
    left: 50%;
    top: 20px;
    transform: translateX(-50%);
    width: 208px;
  }
  .input-check {
    width: 208px;
    height: 40px;
    position: relative;
    z-index: 2;
    opacity: 0;
  }
  span {
    width: 40px;
    height: 40px;
    border-radius: 4px;
    font-size: 20px;
    font-weight: 500;
    color: #414655;
    outline: none;
    border: 1px solid #f2f2f6;
    text-align: center;
    margin-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:last-child {
      margin-right: 0;
    }
  }
}
.check-sec {
  font-size: 16px;
  color: #2a2b2c;
  text-align: center;
  line-height: 34px;
}
.pop-check {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .main {
    background-color: #fff;
    width: 480px;
    border-radius: 20px;
    overflow: hidden;
  }
  .check-title {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    height: 48px;
    padding: 0 32px;
    background: #179cff;
    position: relative;
    strong {
      font-size: 18px;
      font-weight: normal;
    }
    .close {
      position: absolute;
      top: 15%;
      right: 24px;
      width: 34px;
      height: 34px;
      // transform: translateY(-50%);
      display: block;
      background: url("@/assets/images/pay/close01.png") no-repeat center center;
      background-size: 56%;
      cursor: pointer;
      transition: all 0.4s;
      &:hover {
        transform: rotate(-90deg);
      }
    }
  }
}
.check-main {
  padding: 30px 40px;
}
/deep/.el-form-item {
  position: relative;
}
/deep/.get-code {
  left: auto;
  right: 16px;
}
.link {
  text-align: center;
  p {
    margin-top: 16px;
  }
}
.link-a {
  width: 60%;
}
</style>
